
import { Vue, Component } from 'vue-property-decorator';
import { Carousel, Slide } from 'vue-carousel';
import BaseButton from '@/components/baseComponents/BaseButton.vue';
import IconArrow from '@/components/icons/IconArrow.vue';

@Component({
  components: {
    Carousel,
    Slide,
    BaseButton,
    IconArrow,
  },
})
export default class HowItWorks extends Vue {
  currentSlide = 0;
  cards = [
    {
      title: 'Bank + Accounting',
      description: 'Save time by syncing your transactions',
      video: 'save-time',
    },
    {
      title: 'Bank + Bank',
      description: 'Consolidate all your cashflows in one place',
      video: 'one-place',
    },
    {
      title: 'Bank + Accounting + Payroll',
      description: 'Make your employees happy again with no gap in cashflow',
      video: 'employees',
    },
    {
      title: 'Accounting + Bank + Bureaus',
      description: 'Boost your business credit score & get better funding options',
      video: 'boost',
    },
  ];

  get progressBarWidth(): string {
    return ((100 / this.cards.length) * (this.currentSlide + 1)).toString();
  }
  get videoFormat(): string {
    const isIOS = navigator.userAgent.match(/iPhone/i);
    return isIOS ? 'mp4' : 'webm';
  }

  prevSlide(): void {
    if (this.currentSlide === 0) this.currentSlide = this.cards.length;
    else this.currentSlide -= 1;
  }
  nextSlide(): void {
    if (this.currentSlide === this.cards.length - 1) this.currentSlide = 0;
    else this.currentSlide += 1;
  }
  changeSlide(index: number): void {
    this.currentSlide = index;
  }
  goToRegistration(): void {
    this.$router.push({ name: 'RegistrationPage' });
  }
}
